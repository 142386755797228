.fullscreen-wrapper.fullscreen-enabled > div:first-child {
  position: absolute;
	top: 50%;
	width: 100%;
	transform: translateY(-50%);
}

.fullscreen-wrapper.fullscreen-enabled .player-controls {
	position: absolute;
	bottom: 0;
	border-radius: 0;
}

@media (min-width: 640px) {
	.fullscreen-wrapper.fullscreen-enabled .player-controls {
		transition: opacity 0.3s;
		opacity: 0%;
	}

	.fullscreen-wrapper.fullscreen-enabled:hover .player-controls {
		opacity: 80%;
	}
}

/* CSS fullscreen for iphone */
.fullscreen:not(.fullscreen-enabled) .fullscreen-wrapper.fullscreen-enabled {
 position: fixed;
 height: 100%;
 width: 100%;
 top: 0;
 left: 0;
 background: #000;
 z-index: 9999;
}

.animated-background {
	background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
