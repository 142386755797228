.container {
  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 24px;
  }

  ul {
    list-style: disc;
  }

  ul, ol {
    padding-left: 25px;
  }
}
