.ui-video-seek-slider {
  .track .main .connect,
  .thumb .handler {
    background-color: #5fd6c1;
  }

  .thumb .handler {
    box-shadow: 0 0 2px 0px #000;
  }

  .hover-time {
    display: none;
  }

  .thumb {
    height: 16px;
    width: 16px;
    top: 1px;
  }
}
